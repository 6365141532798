<template>
    <div>
        <div class="row profile-header page-header">
            <div class="col">
                <div class="row">
                    <div class="col d-flex justify-content-between align-items-center">
                        <div class="page-title-wrapper">
                            <h1 class="profile-title page-title">
                                Privacy Policy
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div @click="goBack" class="back-button" v-show="$router.currentRoute.name !== $constants.routeNames.campaigns.root">
                            <span class="btn btn-link btn-back"><i class="icon tim-icons icon-double-left" aria-hidden="true"></i>
                                <div class="label">Back</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card static-page">
                    <p>
                    Publicis Groupe built the Publicis Connect app as a Commercial app. This SERVICE is provided by Publicis Groupe and is intended for use as
                    is.<br>
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone
                    decided to use our Service.<br>
                    If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal
                    Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as
                    described in this Privacy Policy.<br>
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Publicis Connect unless
                    otherwise defined in this Privacy Policy.
                    <br><br></p>

                    <p>
                    <b>Information Collection and Use</b><br>
                    For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information,
                    including but not limited to email, name, last name, company. The information that we request will be retained by us and used as described
                    in this privacy policy.
                    <br><br></p>

                    
                    <p>The app does use third party services that may collect information used to identify you.
                    <br><br></p>

                    <p>
                    <b>Log Data</b><br>
                    We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third
                    party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address,
                    device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the
                    Service, and other statistics.
                    <br><br></p>

                    <p>
                    <b>Cookies</b><br>
                    Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from
                    the websites that you visit and are stored on your device's internal memory.
                    <br><br></p>

                    <p>
                    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect
                    information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to
                    your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                    <br><br></p>

                    <p>
                    <b>Service Providers</b><br>
                    We may employ third-party companies and individuals due to the following reasons:
                    <br><br></p>

                    <p>
                    To facilitate our Service;<br>
                    To provide the Service on our behalf;<br>
                    To perform Service-related services; or<br>
                    To assist us in analyzing how our Service is used.<br><br>
                    We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the
                    tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                    <br><br></p>

                    <p>
                    <b>Security</b><br>
                    We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it.
                    But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                    <br><br></p>

                    <p>
                    <b>Changes to This Privacy Policy</b><br>
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify
                    you of any changes by posting the new Privacy Policy on this page.
                    <br><br></p>

                    <p>
                    This policy is effective as of 2020-10-19
                    <br><br></p>

                    <p>
                    <b>Contact Us</b>
                    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:help@publicisconnect.io">help@publicisconnect.io</a>.
                    <br><br></p>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "privacy-policy",
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
