<template>
    <div>
        <!-- <div class="row profile-header page-header">
            <div class="col">
                <div class="row">
                    <div class="col d-flex justify-content-between align-items-center">
                        <div class="page-title-wrapper">
                            <h1 class="profile-title page-title">
                                Profile
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            @click="goBack"
                            class="back-button"
                            v-show="$router.currentRoute.name !== $constants.routeNames.campaigns.root"
                        >
                            <span class="btn btn-link btn-back"
                                ><i class="icon tim-icons icon-double-left" aria-hidden="true"></i>
                                <div class="label">Back</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <Tabs class="user-profile-tabs tabs">
                    <Tab label="Basic info" >
                        <h2>
                            Basic info
                        </h2>
                        <form>
                            <div class="user-profile-form">
                                <TextInput v-model="name" label="Name" />
                                <TextInput disabled v-model="company" label="Company" />
                                <TextInput v-model="email" label="Email" />
                            </div>

                            <div class="text-right">
                                <BaseButton class="save-button">Save changes</BaseButton>
                            </div>
                        </form>
                    </Tab>
                    <Tab label="Change password">
                        <h2>Change password</h2>
                        <form>
                            <div class="user-profile-form">
                                <TextInput type="password" label="Current password" :required="true"  v-model="currentPassword"/>
                                <el-tooltip  manual v-model="passwordNotStrong" content="Password does not meet requirements" placement="bottom">
                                    <TextInput
                                        :required="true"
                                        v-model="newPassword"
                                        v-on:focus="resetFieldsTooltips"
                                        type="password"
                                        label="New password"
                                    />
                                </el-tooltip>

                                <el-tooltip manual v-model="passwordsNotSame" content="Password does not match" placement="bottom">
                                   <TextInput
                                        :required="true"
                                        v-model="newPasswordCheck"
                                        :isValid="passwordCheck"
                                        v-on:focus="resetFieldsTooltips"
                                        type="password"
                                        label="Repeat new password"
                                    />
                                </el-tooltip>
                                <p class="password-note">
                                    Password have to meet following requirements:
                                    <ul>
                                        <li>Minimum 8 characters</li>
                                        <li>1 Uppercase letter</li>
                                        <li>1 number</li>
                                    </ul>
                                </p>
                            </div>

                            <div class="text-right">
                                <BaseButton class="save-button" @click="changePassword">Save changes</BaseButton>
                            </div>
                        </form>
                    </Tab>
                    <!--  TODO backend setup endpoint for this
                     disabling for now -->
                    <!-- <Tab label="Email notifications"
                        ><h2>Email notifications</h2>
                        <div class="user-profile-form wide">
                            <BaseRadio
                                v-model="emailNotification"
                                class="user-profile-radio"
                                inputName="email_notification"
                                name="all"
                                >All changes</BaseRadio
                            >
                            <p>
                                You'll get an email whenever someone creates a brief, make changes in briefs, campaigns
                                or edits anything in reporting. You will also get an email when someone make comments to
                                a media plan or a report for a campaign (even if you have not been tagged using @).
                            </p>
                            <BaseRadio
                                v-model="emailNotification"
                                class="user-profile-radio"
                                inputName="email_notification"
                                name="mine"
                                >Changes concerning me</BaseRadio
                            >
                            <p>
                                You'll only get an email whenever someone creates a brief or makes changes to an
                                existing brief, a campaign or a media plan, and also when someone mentions you using a @
                                in the comments (thereby only comments concerning you).
                            </p>
                            <BaseRadio
                                v-model="emailNotification"
                                class="user-profile-radio"
                                inputName="email_notification"
                                name="off"
                                >Off</BaseRadio
                            >
                            <p>
                                You’ll not receive any emails when briefs are created or changes are made to briefs, a
                                campaign or reporting. *Note: if you choose this you will not be updated on any changes
                                made in Connect.*
                            </p>
                        </div>
                        <div class="text-right">
                            <BaseButton class="save-button">Save changes</BaseButton>
                        </div>
                    </Tab> -->
                </Tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

import TextInput from "@/components/Inputs/TextInput.vue";
import BaseRadio from "@/components/Inputs/BaseRadio.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
    name: "UserProfile",
    components: {
        Tabs,
        Tab,
        TextInput,
        BaseButton,
        BaseRadio,
    },

    data() {
        return {
            name: localStorage.username,
            lastName: "lastname",
            company: localStorage.companyName,
            email: localStorage.currentUserEmail,
            emailNotification: "off",

            currentPassword: "",
            newPassword: "",
            newPasswordCheck: "",
            passwordCheck: true,
            passwordNotStrong: false,
            passwordsNotSame: false,
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        cleanPasswordFields(){
            this.currentPassword = "";
            this.newPassword = "";
            this.newPasswordCheck = "";
        },
        resetFieldsTooltips(){
            this.passwordNotStrong = false;
            this.passwordsNotSame = false;
            this.passwordCheck = true;
        },
        changePassword() {
            const first = this.newPassword;
            const second = this.newPasswordCheck;
            const path = this.$constants.apiPaths.user.changePassword;
            var user = {
                name: localStorage.username,
                email: localStorage.currentUserEmail,
                currentPassword: this.currentPassword,
                password: second}
            const payload = user;
            
            let passwordRequirements = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
            this.passwordNotStrong = !passwordRequirements.test(this.newPassword);

            if (first !== second) {
                this.passwordCheck = false;
                this.passwordsNotSame = true;
            }
            else if (this.passwordNotStrong) {
                //this.passwordCheck = false;
            } else {
                const that = this;
                this.axios.put(path, payload)
                    .then(() => {
                        that.cleanPasswordFields();
                        this.$swal.fire({
                            title: 'Password updated',
                            icon: 'success',
                            timer: 3000
                        });
                    })
                    .catch(() => {
                        this.$swal.fire({
                            title: 'Wrong password',
                            icon: 'error',
                            timer: 3000
                        });
                    });
            }
        },
    },
};
</script>
<style lang="scss">
.user-profile-tabs {
    .save-button {
        width: fit-content;
    }

    .user-profile-radio {
        label {
            font-weight: 500;
            color: #fff;
            text-decoration: underline;
        }

        & + p {
            color: #fff;
            margin-bottom: 30px;
        }
    }
}

.user-profile-form {
    max-width: 300px;
    margin-bottom: 50px;

    &.wide {
        max-width: 580px;
    }
}
</style>
